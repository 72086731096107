import { useEffect } from "react"
import { navigate } from "gatsby"
import i18n from "../i18n/i18n"

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en"
  }

  const lang = navigator && navigator.language && navigator.language.split("-")[0]
  if (!lang) return "en"

  switch (lang) {
    case "de":
      return "de"
    default:
      return "en"
  }
}

const IndexPage = () => {
  useEffect(() => {
    const processData = async () => {
        const urlLang = getRedirectLanguage()
        await i18n.changeLanguage(urlLang)
        navigate(`/${urlLang}`, {replace: true})
    }
    processData()
  }, [])

  return null
};

export default IndexPage

